import React from 'react';

import ThinkingAnimation from '@/components/ThinkingAnimation';

const Test = () => {
  return (
    <div>
      Test
      <ThinkingAnimation />
    </div>
  );
};

export default Test;
