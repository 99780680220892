import { Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import NotificationHandler from '@/utils/NotificationHandler';

import 'react-toastify/dist/ReactToastify.css'; // Import toastify CSS

// Layouts
import MainLayout from '@/components/Layouts/MainLayout';

import { PrivateGuard } from '@/guards/PrivateGuard';
// Pages
import HomePage from '@/pages/Home';
import Dashboard from '@/pages/cases/Dashboard';
import LoginPage from '@/pages/auth/LoginPage';
import RegisterPage from '@/pages/auth/RegisterPage';
import ForgotPassword from '@/pages/auth/ForgotPassword';
import ResetPassword from '@/pages/auth/ResetPassword';
import EmailVerificationPage from '@/pages/auth/EmailVerificationPage';
// Routes

import OutsourcingRoutes from '@/pages/outsourcing';
import SettingRoutes from '@/pages/setting';
import CasesRoutes from '@/pages/cases';
import ConditionsRoutes from '@/pages/conditions';
import AdminRoutes from '@/pages/admin';
import GuideRoutes from '@/pages/guides';
import DocsRoutes from '@/pages/docs';
import Test from '@/pages/Test';

// Not Found Page
import { RoutesWithNotFound } from '@/components/RoutesWithNotFound/RoutesWithNotFound';

function App() {
  return (
    <>
      <ToastContainer position="bottom-right" />{' '}
      {/* Toast notifications container */}
      <NotificationHandler />
      <RoutesWithNotFound>
        <Route element={<MainLayout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="test" element={<Test />} />
          <Route path="login" element={<LoginPage />} />
          <Route path="register" element={<RegisterPage />} />
          <Route path="forgotpassword" element={<ForgotPassword />} />
          <Route path="reset/:resetToken" element={<ResetPassword />} />
          <Route path="verify/:token" element={<EmailVerificationPage />} />
          <Route path="docs/*" element={<ConditionsRoutes />} />
          <Route path="doc/*" element={<DocsRoutes />} />
          <Route path="guides/*" element={<GuideRoutes />} />

          <Route element={<PrivateGuard />}>
            <Route path="cases/*" element={<CasesRoutes />} />
            <Route path="setting/*" element={<SettingRoutes />} />
            <Route path="admin/*" element={<AdminRoutes />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="outsourcing/*" element={<OutsourcingRoutes />} />

            {/* <Route path="cases">
              <Route path="*" element={<CasesRoutes />} />+{' '}
            </Route>
            <Route path="setting">
              <Route path="*" element={<SettingRoutes />} />+{' '}
            </Route>
            <Route path="admin">
              <Route path="*" element={<AdminRoutes />} />+{' '}
            </Route>
            <Route path="outsourcing">
              <Route path="*" element={<OutsourcingRoutes />} />+{' '}
            </Route>
            <Route path="dashboard" element={<Dashboard />} /> */}
          </Route>
        </Route>
      </RoutesWithNotFound>
    </>
  );
}

export default App;
