import styles from './ThinkingAnimation.module.css';

const ThinkingAnimation = () => {
  return (
    <div className={styles.container}>
      <div className={styles.dotsContainer}>
        {[...Array(3)].map((_, index) => (
          <div
            key={index}
            className={styles.dot}
            style={{
              backgroundColor:
                index === 0
                  ? 'rgb(63, 84, 255)' // Primary blue
                  : index === 1
                  ? 'rgb(199, 183, 255)' // Light purple
                  : 'rgb(255, 242, 183)', // Light yellow
              animationDelay: `${index * 0.2}s`,
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default ThinkingAnimation;
