import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { verifyEmail, checkToken } from '@/services/auth';
import { toast } from 'react-toastify';
import ThinkingAnimation from '@/components/ThinkingAnimation';

const EmailVerificationPage = () => {
  const [tokenChecked, setTokenChecked] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const response = await checkToken(token);
        if (response) {
          setTokenChecked(true);
          await verifyEmail(token);
          toast.success('Correo Validado exitosamente');
          navigate('/');
        } else {
          setError('Token expirado o invalido.');
        }
      } catch (error) {
        console.error('Error checking token:', error);
        setError('An error occurred while verifying the token');
      } finally {
        setLoading(false);
      }
    };

    checkTokenValidity();
  }, [token]);

  return (
    <div>
      {loading && (
        <div className="flex justify-center items-center h-full">
          <ThinkingAnimation />
        </div>
      )}
    </div>
  );
};

export default EmailVerificationPage;
