import { toast } from 'react-toastify';
import React, { useState, useRef } from 'react';
import TeethDemandCard from '@/components/Cards/TeethDemandCard';
import CaseTypeCard from '@/components/Cards/CaseTypeCard';
import { Card, CardContent } from '@/components/ui/card';
import { ScrollArea } from '@/components/ui/scroll-area';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { useDemandStore } from '@/store/DemandStore';
import useUserStore from '@/store/userStore';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Progress } from '@/components/ui/progress';
import { X } from 'lucide-react';
import { createFile } from '@/services/file';

const CaseDetailCard = () => {
  const { user } = useUserStore();
  const { demand } = useDemandStore();
  const [files, setFiles] = useState([]);
  const [isAttaching, setIsAttaching] = useState(false);
  const [progress, setProgress] = useState(0);
  const fileInputRef = useRef(null);
  const handleFileChange = async (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setIsAttaching(true);
      setProgress(0);
      const newFiles = Array.from(event.target.files);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);

      for (const file of newFiles) {
        await simulateFileAttachment(file);
      }

      setIsAttaching(false);
      setProgress(0);
    }
  };
  const simulateFileAttachment = async (file) => {
    return new Promise((resolve) => {
      const totalSize = file.size;
      let loadedSize = 0;
      const chunkSize = totalSize / 10; // Simular 10 pasos de carga

      const loadChunk = () => {
        loadedSize += chunkSize;
        const fileProgress = (loadedSize / totalSize) * 100;
        setProgress((prevProgress) =>
          Math.min(
            prevProgress + (100 / files.length) * (fileProgress / 100),
            100,
          ),
        );

        if (loadedSize < totalSize) {
          setTimeout(loadChunk, 100); // Simular un retraso de 100ms por chunk
        } else {
          resolve();
        }
      };

      loadChunk();
    });
  };

  const handleDeleteFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (files) {
        for (const file of files) {
          const fileData = {
            file,
          };
          await createFile({ id: demand._id, fileData });
        }
        window.location.reload();
        setFiles([]);
        toast.success('Archivo(s) adjuntado(s) exitosamente');
      }
    } catch (error) {
      toast.error('Error al adjuntar archivo(s)');
    }
  };

  return (
    <Card className="py-4 px-2  gap-8 rounded-lg bg-white ">
      <CardContent className=" flex flex-col md:flex-row p-0">
        <div className="w-full md:w-[30%]">
          <TeethDemandCard />
        </div>
        <div className=" md:w-2/3 space-y-3 ">
          <h1 className="font-urbanist px-4 text-2xl">
            <strong>{demand.caseName}</strong>
          </h1>
          <p className="px-4 py-2 text-sm font-inter">{demand.description}</p>

          <div className=" flex  flex-col md:flex-row items-start  lg:gap-[30px] lg:px-5 ">
            <CaseTypeCard demand={demand} className="w-full md:w-1/2" />

            <div className="w-full md:w-1/2 lg:px-5">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead className="font-bold text-xs font-urbanist">
                      Archivos
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody className="block">
                  {demand?.files?.length ? (
                    demand.files.map((file) => (
                      <TableRow key={file._id}>
                        <TableCell className="text-center">
                          <a
                            href={file.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                            className="text-blue-500"
                          >
                            {file.originalName}
                          </a>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan="2" className="text-center">
                        No hay archivos
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              {user.role !== 'SELLER_ROLE' && (
                <div className="w-full max-w-md  space-y-3 px-2">
                  <div className="space-y-2">
                    <Label htmlFor="file-upload">
                      {files.length > 0
                        ? 'Adjuntar más archivos'
                        : 'Seleccionar Archivos'}
                      <div className="text-[8px] p-1 w-fit bg-[#BDCAFE]">
                        {' '}
                        .stl, .obj, .ply, .dentalproject, .3oxs, .dcm, .mod,
                        .jpg, .png
                      </div>
                    </Label>
                    <Input
                      id="file-upload"
                      type="file"
                      accept="
                  .stl, .obj, .ply, .dentalproject, .constructioninfo, .3oxs, .dcm, .mod, .jpg, .png"
                      multiple
                      onChange={handleFileChange}
                      disabled={isAttaching}
                      ref={fileInputRef}
                      className=" text-center py-2 content-center btn-neutral input-styles"
                    />
                  </div>
                  {files.length > 0 && (
                    <div>
                      <div className="flex justify-between items-center mb-2">
                        <p className="text-sm text-gray-500">
                          {files.length} archivo
                          {files.length !== 1 ? 's' : ''} adjuntado
                          {files.length !== 1 ? 's' : ''}
                        </p>
                      </div>
                      <ul className="text-sm text-gray-700 space-y-2">
                        {files.map((file, index) => (
                          <li
                            key={index}
                            className="flex justify-between items-center"
                          >
                            <span className="truncate">{file.name}</span>
                            <Button
                              variant="ghost"
                              size="sm"
                              onClick={() => handleDeleteFile(index)}
                              disabled={isAttaching}
                            >
                              <X className="w-4 h-4" />
                              <span className="sr-only">
                                Borrar {file.name}
                              </span>
                            </Button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {(isAttaching || progress > 0) && (
                    <div className="space-y-2">
                      <Progress value={progress} className="w-full" />
                      <p className="text-sm text-center">
                        {isAttaching
                          ? 'Adjuntando archivos...'
                          : `${Math.round(progress)}% completado`}
                      </p>
                    </div>
                  )}
                  {files.length > 0 && (
                    <Button
                      type="submit"
                      className=" btn-primary ml-auto justify-end"
                      onClick={handleSubmit}
                    >
                      Subir archivos
                    </Button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default CaseDetailCard;
