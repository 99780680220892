import React, { useState, useEffect } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import useUserStore from '@/store/userStore';
import useDemandStore from '@/store/DemandStore';
import { getbillLab } from '@/services/billLab';
import { FaDownload } from 'react-icons/fa';
import BillLabStepper from '@/components/Cards/BillLabStepper';
import ThinkingAnimation from '@/components/ThinkingAnimation';
import { Card, CardContent } from '@/components/ui/card';
import {
  getPaymentIntent,
  createPaymentBillLab,
} from '@/services/payment.service';
import { toast } from 'react-toastify';

const BillLapPage = () => {
  const { user } = useUserStore();
  const { setDemand } = useDemandStore();
  const { billId } = useParams();
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');
  const [billingData, setBillingData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      const { demand, billlab } = await getbillLab(billId);
      setBillingData(billlab);
      setDemand(demand);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching billing data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [billId]);

  useEffect(() => {
    const handlePaymentSuccess = async () => {
      try {
        if (sessionId) {
          // Obtén los detalles del pago desde Stripe o tu backend
          const data = {
            sessionId,
          };
          const paymentIntent = await getPaymentIntent(data);

          if (paymentIntent) {
            const paymentData = {
              sessionId,
              billId,
            };
            // Envía los datos de pago a tu backend para registrarlos
            await createPaymentBillLab(paymentData);
            fetchData();
            toast.success('Pago realizado con éxito');
          }
        }
      } catch (error) {
        console.error('Error updating order status:', error);
      }
    };

    handlePaymentSuccess();
  }, [sessionId]);

  const getFileName = (url) => {
    return url.substring(url.lastIndexOf('/') + 1);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-full">
        <ThinkingAnimation />
      </div>
    );
  }

  return (
    <div className="casePage">
      <div className="flex flex-col gap-3 ">
        {user.role == 'SELLER_ROLE' && (
          <BillLabStepper BillLabCase={billingData} />
        )}

        {billingData.file?.url && (
          <Card className="py-4 px-2  gap-8 rounded-lg bg-white ">
            <CardContent className="w-full flex flex-col md:flex-row p-0">
              <div className="mx-auto">
                {billingData.file.mimetype.startsWith('image/') && (
                  <>
                    <img
                      src={billingData.file.url}
                      alt="Message Attachment"
                      className="w-[10rem] h-[10rem] md:w-1/2 md:h-auto mb-2 cursor-pointer"
                    />
                    <a
                      href={billingData.file.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 mt-2 inline-block"
                      download
                    >
                      <FaDownload className="inline mr-2" />
                      Descargar factura
                    </a>
                  </>
                )}

                <div className="flex flex-row justify-between">
                  <div>
                    {billingData.file.mimetype.startsWith('application/') && (
                      <>
                        <iframe
                          src={billingData.file.url}
                          title="Document Viewer"
                          className="w-full h-auto mb-2"
                        />
                        {/* Download Button for Documents */}
                        <a
                          href={billingData.file.url}
                          download={getFileName(billingData.file.url)}
                          className="text-blue-500"
                        >
                          <FaDownload className="inline mr-2" />
                          Descargar documento
                        </a>
                      </>
                    )}
                    <div>Monto {billingData.amount}</div>
                  </div>
                  {/* {billingData.status == 'created' && (
                    <button
                      className="bg-blue-500 hover:bg-blue-700 text-white font-inter font-bold py-2 px-4 rounded"
                      onClick={handleCheckout}
                      disabled={billingData.status == 'paid'}
                    >
                      Pagar
                    </button>
                  )} */}
                </div>
              </div>
            </CardContent>
          </Card>
        )}
      </div>
    </div>
  );
};

export default BillLapPage;
